import {
    default as dataGameFour,
    default as gameFourData,
} from '@/data/gameFourData.json';
import globalData from '@/data/global.json';
import styles from '@/features/components/gameFour/gameFour.module.scss';
import TvActionHeader from '@/features/devices/tv/components/HUD/TvActionHeader';
import { useGame4Store } from '@/store/games/game4Store';
import type {
    DemandStatus,
    JobOmitGame4,
    PossibleJobGame4Ids,
} from '@/types/games/game4/types';

import ButtonFactor from '@/features/devices/tablets/components/contraintSelection/ButtonFactor';
import DemandingFactorRecap from '@/features/devices/tv/components/demandingFactors/DemandingFactorRecap';
import { handleColor } from '@/services/games/game4/gameFourUtils';
import type { Nullable } from '@/types/global/types';
import { motion } from 'framer-motion';

const imageVariants = {
    hidden: { opacity: 0, x: -400 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, x: 400, transition: { duration: 0.5 } },
};

const risksFactors = gameFourData.riskFactors;

const GameFour: React.FC = () => {
    const selectedJob = useGame4Store.use.selectedJob();

    return (
        <>
            <div className={styles.gameFourContainer}>
                <LeftContainer selectedJob={selectedJob} />
                {selectedJob && <MiddleContainer selectedJob={selectedJob} />}
                {selectedJob?.isValidated ? (
                    <RightContainerJobValidated />
                ) : (
                    <RightContainer />
                )}
            </div>
        </>
    );
};

const LeftContainer = ({
    selectedJob,
}: {
    selectedJob: Nullable<JobOmitGame4>;
}) => {
    return (
        <div className={styles.leftContainer}>
            <div className={styles.header}>
                <TvActionHeader title='Action' text={dataGameFour.guideline} />
            </div>
            {selectedJob != null && (
                <div className={styles.wrapper}>
                    <TvActionHeader
                        title={
                            selectedJob?.firstName +
                            ' - ' +
                            selectedJob?.name.toUpperCase()
                        }
                        text={selectedJob?.description}
                        color='green'
                        border={true}
                        isList
                    />
                </div>
            )}
        </div>
    );
};

const MiddleContainer = ({ selectedJob }: { selectedJob: JobOmitGame4 }) => {
    return (
        <>
            <div>
                {selectedJob ? (
                    <motion.img
                        src={selectedJob.fullIcon}
                        alt={selectedJob.slug}
                        className={styles.character}
                        key={selectedJob.id}
                        variants={imageVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                    />
                ) : (
                    <div style={{ width: '500px' }} />
                )}
            </div>
        </>
    );
};

const RightContainer = () => {
    const selectedJob = useGame4Store.use.selectedJob();
    const currentAnswers = useGame4Store.use.currentAnswers();

    return (
        <div className={styles.rightContainer}>
            <h1>{globalData.tv.jobRepartition}</h1>
            <div className={styles.buttonContainer}>
                {risksFactors.map((riskFactor, index) => {
                    if (!selectedJob) return null;
                    const currentStatus =
                        currentAnswers[selectedJob.id as PossibleJobGame4Ids][
                            index
                        ];
                    return (
                        <div key={riskFactor.id} className={styles.buttonFactor}>
                            <h1>{riskFactor.name}</h1>
                            <ButtonFactor soundPath='' text={currentStatus} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const RightContainerJobValidated = () => {
    const currentAnswers = useGame4Store.use.currentAnswers();
    const selectedJob = useGame4Store.use.selectedJob();
    if (!selectedJob) return null;
    const currentAnswersForSelectedJob =
        currentAnswers[selectedJob.id as PossibleJobGame4Ids];
    const goodAnswers =
        dataGameFour.goodAnswers[selectedJob.id as PossibleJobGame4Ids];

    const areAnswersAllCorrect = currentAnswersForSelectedJob.every(
        (answer, index) => answer === goodAnswers[index]
    );

    return (
        <div className={styles.rightContainerRecap}>
            <h1>{globalData.tv.jobRepartition}</h1>
            <div>
                <div className={styles.yourSelectionContainer}>
                    <h2>Votre selection</h2>
                    <div>
                        {risksFactors.map((riskFactor, index) => (
                            <DemandingFactorRecap
                                key={riskFactor.id}
                                color={handleColor(
                                    currentAnswersForSelectedJob[index]
                                )}
                                iconPath={riskFactor.icon}
                                content={riskFactor.name}
                            />
                        ))}
                    </div>
                </div>

                <div className={styles.separator}></div>

                <div className={styles.goodAnswersContainer}>
                    <h2>Bonnes réponses</h2>
                    <div>
                        {risksFactors.map((riskFactor, index) => (
                            <DemandingFactorRecap
                                key={riskFactor.id}
                                color={handleColor(
                                    goodAnswers[index] as DemandStatus
                                )}
                                iconPath={riskFactor.icon}
                                content={riskFactor.name}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div
                className={
                    styles[
                        `combinationRecap${areAnswersAllCorrect ? 'Good' : 'Bad'}`
                    ]
                }
            >
                <div>
                    <h1>
                        {areAnswersAllCorrect ? 'Bravo' : 'Mauvaise combinaison'}
                    </h1>
                    <p>
                        {areAnswersAllCorrect
                            ? 'Félicitations ! Vous avez trouvé la bonne combinaison.'
                            : 'Ce n’est pas tout à fait ça ! Pas de panique, voici la bonne combinaison.'}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default GameFour;
