import dataGameFour from '@/data/gameFourData.json';
import { getJobsData } from '@/services/global/globalUtils';
import createSelectors from '@/store/selectors';
import {
    type DemandStatus,
    type Game4Store,
    type JobGame4,
    type JobOmitGame4,
    type PossibleJobGame4Ids,
} from '@/types/games/game4/types';
import { type StrictOmit } from '@/types/global/types';
import { create } from 'zustand';

const NUMBER_OF_POSSIGLE_STATUS = 3;

export function initializeDefaultAnswersGame4() {
    const keys = Object.keys(dataGameFour.goodAnswers);
    const answers: Record<PossibleJobGame4Ids, DemandStatus[]> = {} as Record<
        PossibleJobGame4Ids,
        DemandStatus[]
    >;

    for (const key of keys) {
        answers[key as unknown as PossibleJobGame4Ids] = Array(
            NUMBER_OF_POSSIGLE_STATUS
        ).fill('Pas sélectionné' as DemandStatus);
    }

    return answers;
}

export const defaultJobs4 = getJobsData(dataGameFour.jobs).map((job) => ({
    ...job,
    isValidated: false,
})) as StrictOmit<JobGame4, 'jobId'>[];

const initialState: Game4Store = {
    jobsGame4: defaultJobs4,
    currentAnswers: initializeDefaultAnswersGame4(),
    selectedJob: null,
};

const useGame4StoreBase = create(() => structuredClone(initialState));

export const useGame4Store = createSelectors(useGame4StoreBase);

export const setSelectedJobDemandingFactor = (job: JobOmitGame4 | null) => {
    useGame4StoreBase.setState({ selectedJob: job });
};

export const setCurrentAnswers = (
    jobId: PossibleJobGame4Ids,
    answerIndex: number,
    demandStatus: DemandStatus
) => {
    useGame4StoreBase.setState((state) => {
        const currentAnswers = { ...state.currentAnswers };
        currentAnswers[jobId][answerIndex] = demandStatus;
        return { currentAnswers };
    });
};

export const setJobIsValidated = (
    selectedJob: StrictOmit<JobGame4, 'jobId'>,
    isValidated: boolean
) => {
    useGame4StoreBase.setState((state) => {
        const jobsGame4 = state.jobsGame4.map((job) => {
            if (job.id === selectedJob.id) {
                if (job.isValidated) return { ...job };
                return { ...job, isValidated };
            }
            return job;
        });

        return { jobsGame4 };
    });

    useGame4StoreBase.setState((state) => {
        const selectedJob = state.selectedJob;
        if (selectedJob && selectedJob.id === selectedJob.id) {
            return { selectedJob: { ...selectedJob, isValidated } };
        }
        return state;
    });
};

export const resetCurrentAnswers = () =>
    useGame4StoreBase.setState({
        currentAnswers: initializeDefaultAnswersGame4(),
    });

export const resetGame4 = () =>
    useGame4StoreBase.setState(structuredClone(initialState));
