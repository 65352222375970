import { ListItemClick } from '@/assets/sounds/soundsEffects';
import dataGameFour from '@/data/gameFourData.json';
import ActionButton from '@/features/devices/tablets/components/buttons/ActionButton';
import ButtonFactor from '@/features/devices/tablets/components/contraintSelection/ButtonFactor';
import styles from '@/features/devices/tablets/components/contraintSelection/contraintSelection.module.scss';
import { handleSelectCurrentAnswer } from '@/services/games/game4/gameFourHandleFunctions';
import { getNextDemandStatus } from '@/services/games/game4/gameFourUtils';
import { isKey } from '@/services/global/globalUtils';
import { useGame4Store } from '@/store/games/game4Store';
import type { DemandStatus, PossibleJobGame4Ids } from '@/types/games/game4/types';

const ContraintSelection: React.FC = () => {
    const risksFactors = dataGameFour.riskFactors;
    const selectedJob = useGame4Store.use.selectedJob();
    const currentAnswers = useGame4Store.use.currentAnswers();

    if (!selectedJob) return;

    return (
        <div className={styles.containerContraint}>
            <div>
                <p>{dataGameFour.instructionTablet}</p>
            </div>
            <div>
                {risksFactors.map((riskFactor, index) => {
                    if (isKey(currentAnswers, selectedJob.id)) {
                        const currentAnswer = currentAnswers[selectedJob.id][index];
                        return (
                            <div key={riskFactor.id}>
                                <h2>{riskFactor.name}</h2>
                                <ButtonFactor
                                    onClick={() =>
                                        handleSelectCurrentAnswer(
                                            selectedJob.id as PossibleJobGame4Ids,
                                            index,
                                            getNextDemandStatus(currentAnswer)
                                        )
                                    }
                                    soundPath={ListItemClick}
                                    text={currentAnswer}
                                />
                            </div>
                        );
                    }
                })}
                <ActionButton
                    action='validateJobGame4'
                    color='orange'
                    title='Valider'
                    disabled={risksFactors.some((_, index) => {
                        if (isKey(currentAnswers, selectedJob.id)) {
                            const currentAnswer =
                                currentAnswers[selectedJob.id][index];
                            return (
                                currentAnswer ===
                                ('Pas sélectionné' as DemandStatus)
                            );
                        }
                    })}
                />
            </div>
        </div>
    );
};

export default ContraintSelection;
