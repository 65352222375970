import gameFourData from '@/data/gameFourData.json';
import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import {
    handleResetCurrentAnswers,
    handleSelectCurrentAnswer,
    handleSelectGame4Job,
    handleValidateGame4,
    handleValidateJobGame4,
} from '@/services/games/game4/gameFourHandleFunctions';
import { useGame4Store } from '@/store/games/game4Store';
import type { DemandStatus, PossibleJobGame4Ids } from '@/types/games/game4/types';

const gameFourCorrectAnswers = gameFourData.goodAnswers;

const GameFourMediator = () => {
    const jobsDemandingFactor = useGame4Store.use.jobsGame4();
    const selectedJob = useGame4Store.use.selectedJob();

    return (
        <div>
            {/* Select the good answers for the selected job */}
            <MediatorAction
                title={`Sélectionner les bonnes réponses pour ${selectedJob?.firstName}`}
                displayingCondition={
                    selectedJob !== null && !selectedJob.isValidated
                }
                handleFunction={() => {
                    const goodAnswer = Object.keys(gameFourCorrectAnswers).find(
                        (answer) => parseInt(answer) === selectedJob!.id
                    )!;

                    const goodAnswerValue = gameFourCorrectAnswers[
                        goodAnswer as keyof typeof gameFourCorrectAnswers
                    ] as DemandStatus[];

                    goodAnswerValue.forEach((answer, index) => {
                        handleSelectCurrentAnswer(
                            selectedJob!.id as PossibleJobGame4Ids,
                            index,
                            answer
                        );
                    });

                    handleValidateJobGame4();
                }}
            />

            {/* Passer a la sutie */}
            <MediatorAction
                title='Passer a la suite'
                displayingCondition={
                    selectedJob !== null && selectedJob.isValidated
                }
                handleFunction={handleValidateGame4}
            />

            {/* Select a job */}
            {jobsDemandingFactor.map((job) => {
                return (
                    <MediatorAction
                        key={job.id}
                        title={`Selectionner ${job.firstName}`}
                        displayingCondition={
                            selectedJob?.id !== job.id && !job.isValidated
                        }
                        handleFunction={() => {
                            handleSelectGame4Job(job);
                        }}
                        type='secondary'
                    />
                );
            })}

            {/* Reset all the exposure status */}
            <MediatorAction
                title='Réinitialiser les combinaisons'
                displayingCondition
                handleFunction={handleResetCurrentAnswers}
                type='tertiary'
            />
        </div>
    );
};

export default GameFourMediator;
