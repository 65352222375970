import data from '@/data/gameOneData.json';
import globalData from '@/data/global.json';
import styles from '@/features/components/gameOne/gameOne.module.scss';
import ErrorAndSuccessScreen from '@/features/components/modal/errorAndSuccess/ErrorAndSuccess';
import ClickableItem from '@/features/devices/tablets/components/list/components/ClickableItem';
import DisplayJobs from '@/features/devices/tv/components/jobs/DisplayJobs';
import TvTextBanner from '@/features/devices/tv/components/tvTextBanner/TvTextBanner';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import {
    getSelectedLever,
    getSelectedRisk,
} from '@/services/games/game1/gameOneUtils';
import { totalRisks } from '@/services/global/globalUtils';
import { useGame1Store } from '@/store/games/game1Store';
import { OverlayType } from '@/types/global/types';
import { motion } from 'framer-motion';

const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.3 } },
};

const GameOne: React.FC = () => {
    const {
        risks,
        risksFound: isRisksFound,
        leversFound: isLeversFound,
        job: currentJob,
        audioShouldPlay,
    } = useGame1Store();
    const { currentTextAudio, currentTitle, currentText, overlayType } =
        useCurrentOverlay();

    // The banner is shown if the risks are not all found or if there are risks in validation
    const showBanner =
        risks.length !== totalRisks ||
        (currentJob?.risksValidated && !currentText && !isLeversFound);

    return (
        <div className={styles.gameOneContainer}>
            {!showBanner ? (
                <>
                    {audioShouldPlay ? (
                        <DisplayJobs />
                    ) : (
                        <>
                            {currentText ? (
                                <ErrorAndSuccessScreen
                                    color={
                                        overlayType === OverlayType.SUCCESS
                                            ? 'green'
                                            : 'red'
                                    }
                                    text={currentText}
                                    title={currentTitle}
                                    audio={currentTextAudio}
                                />
                            ) : (
                                <GameOneCoreComponent />
                            )}
                        </>
                    )}
                </>
            ) : (
                <TvTextBanner
                    text={
                        isRisksFound
                            ? data.guideline.leversUnlockInstruction
                            : data.guideline.risksUnlockInstruction
                    }
                    currentNumber={isRisksFound ? undefined : risks.length}
                    totalNumber={isRisksFound ? undefined : totalRisks}
                    tabletName={globalData.global.red}
                />
            )}
        </div>
    );
};

export default GameOne;

const GameOneCoreComponent = () => {
    const currentJob = useGame1Store.use.job();
    const risks = useGame1Store.use.risks();
    const levers = useGame1Store.use.levers();
    const itemType = currentJob.risksValidated ? 'levers' : 'risks';

    const selectedRiskOrLever = currentJob.risksValidated
        ? getSelectedLever()
        : getSelectedRisk();

    const validatedRisksOrLevers = currentJob.risksValidated
        ? levers.filter((risk) => risk.isValidated)
        : risks.filter((lever) => lever.isValidated);

    const risksOrLeversIdsLeft = currentJob[`${itemType}Id`]?.filter(
        (id) => !validatedRisksOrLevers.find((item) => item.id === id)
    );

    return (
        <>
            <div className={styles.itemsContainer}>
                <h2>{currentJob?.name}</h2>

                <p>
                    {itemType === 'risks'
                        ? globalData.tv.identifyRisks
                        : globalData.tv.identifyLevers}
                </p>

                <div>
                    {validatedRisksOrLevers?.map((item) => {
                        return (
                            <motion.div variants={itemVariants} key={item.id}>
                                <ClickableItem
                                    description={item.name}
                                    isValidated
                                />
                            </motion.div>
                        );
                    })}

                    {risksOrLeversIdsLeft?.map((_, index) => {
                        const isFirstIndex = index === 0;

                        return (
                            <motion.div variants={itemVariants} key={index}>
                                <ClickableItem
                                    description={
                                        isFirstIndex
                                            ? selectedRiskOrLever?.name
                                            : undefined
                                    }
                                    isSelected={
                                        (isFirstIndex &&
                                            selectedRiskOrLever?.isSelected) ??
                                        false
                                    }
                                    empty={!selectedRiskOrLever || !isFirstIndex}
                                />
                            </motion.div>
                        );
                    })}
                </div>
            </div>
            <img src={currentJob?.fullIcon ?? ''} alt={currentJob?.name ?? ''} />
        </>
    );
};
