import styles from '@/features/devices/tv/components/demandingFactors/demandingFactorRecap.module.scss';

interface DemandingFactorRecapProps {
    color: string;
    content?: string;
    iconPath: string;
}

const DemandingFactorRecap: React.FC<DemandingFactorRecapProps> = ({
    color,
    content,
    iconPath,
}) => {
    if (content === 'Postures contraignantes') content = 'Postures cont.';
    const containerClass = color ? styles[`${color}Container`] : '';

    return (
        <div className={containerClass}>
            <img src={iconPath} alt='Icône' />
            {content && <p>{content.toUpperCase()}</p>}
        </div>
    );
};

export default DemandingFactorRecap;
