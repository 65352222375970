import { isGoodCombination } from '@/services/games/game3/gameThreeUtils';
import {
    handleGoToGameStatus,
    handleTabletStatus,
    handleUpdateOverlayDescriptions,
} from '@/services/global/globalHandleFunctions';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useGame3Store } from '@/store/games/game3Store';
import { useOverlayStore } from '@/store/overlayStore';
import {
    type Argument,
    type JobOmitGame3,
    type PossibleJobGame3Ids,
} from '@/types/games/game3/types';
import { OverlayType } from '@/types/global/types';

export const handleSelectJobGame3 = (currentJob: JobOmitGame3) => {
    handleTabletStatus({
        blue: false,
        red: true,
        green: true,
        orange: false,
    });
    emitSocketEvent('send_select_job_3', {
        currentJob,
    });
};

export const handleSelectArgumentGame3 = (selectedArgument: Argument) => {
    emitSocketEvent('send_select_argument', {
        selectedArgument,
    });
};

export function handleArgumentIdAlreadySelected(
    argumentId: number,
    reset: boolean
) {
    emitSocketEvent('send_argument_ids_already_used_game_3', {
        argumentIdAlreadyUsed: argumentId,
        reset,
    });
}

export const handleValidateArgumentGame3 = () => {
    const selectedArgument = useGame3Store.getState().selectedArgument;
    const selectedJob = useGame3Store.getState().selectedJob;

    if (!selectedArgument) return;
    if (!selectedJob) return;

    // Check if there is a good combination of job and argument
    const goodCombination = isGoodCombination(
        selectedJob.id as PossibleJobGame3Ids,
        selectedArgument.id
    );

    handleUpdateOverlayDescriptions(
        [
            {
                id: selectedJob?.feedbackAnswers[selectedArgument.id - 1]
                    .argumentId,
                title: '',
                text: selectedJob?.feedbackAnswers[selectedArgument.id - 1].answer,
                audio: selectedJob?.feedbackAnswers[selectedArgument.id - 1]
                    .audioPath,
            },
        ],
        goodCombination ? OverlayType.SUCCESS : OverlayType.ERROR
    );

    goodCombination && handleUpdateValidationStatusJobGame3(true);

    handleArgumentIdAlreadySelected(selectedArgument.id, goodCombination);

    handleTabletStatus({
        blue: true,
        red: false,
        green: false,
        orange: false,
    });
};

export const handleUpdateValidationStatusJobGame3 = (isValidated: boolean) => {
    const selectedJob = useGame3Store.getState().selectedJob;
    if (!selectedJob) return;

    emitSocketEvent('send_update_job_3_validation_status', {
        jobId: selectedJob.id,
        isValidated,
    });
};

export const handleValidateGame3 = () => {
    const overlayType = useOverlayStore.getState().overlayType;
    const areAllJobsValidated = useGame3Store
        .getState()
        .jobsGame3.every((job) => job.isValidated);

    if (overlayType === OverlayType.SUCCESS) {
        handleSelectJobGame3(null as unknown as JobOmitGame3);
        handleSelectArgumentGame3(null as unknown as Argument);

        if (areAllJobsValidated) {
            handleGoToGameStatus('GAME3PREVENTION');
            return;
        }
    }

    handleTabletStatus({
        blue: false,
        red: overlayType !== OverlayType.SUCCESS,
        green: true,
        orange: false,
    });
    handleUpdateOverlayDescriptions([], OverlayType.NEUTRAL);
};
