import overlayContent from '@/data/overlayDeviceContent.json';
import {
    emitSocketEvent,
    getNextGameStatus,
    tabletStatusGameConfig,
} from '@/services/global/globalUtils';
import { useGame1Store } from '@/store/games/game1Store';
import { defaultJobs2 } from '@/store/games/game2Store';
import { defaultJobs3 } from '@/store/games/game3Store';
import {
    defaultJobs4,
    initializeDefaultAnswersGame4,
} from '@/store/games/game4Store';
import { useOverlayStore } from '@/store/overlayStore';
import {
    GAMESTATUS,
    OverlayType,
    type DeviceColor,
    type DeviceColorWithTvAndMediator,
    type GameStatus,
    type OverlayDescription,
} from '@/types/global/types';

export const handleReset = () => {
    emitSocketEvent('send_reset', {});
};

export const handleUpdateCurrentMode = (mode: 'cadenas' | 'pochette') => {
    emitSocketEvent('send_update_mode', { mode });
};

export const handleSendGameStatus = (gameStatus: GameStatus) => {
    emitSocketEvent('send_game_status', { status: gameStatus });
};

export const handleUpdateDeviceActivatedStatus = (
    deviceColor: DeviceColorWithTvAndMediator,
    status: boolean
) => {
    emitSocketEvent('send_device_activated_status', {
        deviceColor,
        status,
    });
};

export const handleSendIsAudioPlaying = (isAudioPlaying: boolean) => {
    const responsesSocket = {
        isAudioPlaying: isAudioPlaying,
    };
    emitSocketEvent('send_is_audio_playing', responsesSocket);
};

export const handleTabletStatus = (deviceActivationStatus: {
    [color in DeviceColor]: boolean;
}) => {
    Object.entries(deviceActivationStatus).forEach(([color, status]) => {
        handleUpdateDeviceActivatedStatus(color as DeviceColor, status);
    });
};

export const handleGoToGameStatus = (gameStatus: GameStatus) => {
    handleSendGameStatus(gameStatus);
    handleTabletStatus(tabletStatusGameConfig[gameStatus]);

    const isGameAction =
        gameStatus === GAMESTATUS.GAME1ACTION ||
        gameStatus === GAMESTATUS.GAME2ACTION ||
        gameStatus === GAMESTATUS.GAME3ACTION ||
        gameStatus === GAMESTATUS.GAME4ACTION;

    if (isGameAction) {
        handleUpdateOverlayDescriptions([], OverlayType.NEUTRAL);
        switch (gameStatus) {
            case GAMESTATUS.GAME1ACTION:
                emitSocketEvent('send_update_job_1', {
                    currentJob: useGame1Store.getState().job,
                });
                break;
            case GAMESTATUS.GAME2ACTION:
                emitSocketEvent('send_update_jobs_2', { jobs: defaultJobs2 });
                break;
            case GAMESTATUS.GAME3ACTION:
                emitSocketEvent('send_update_jobs_3', { jobs: defaultJobs3 });
                break;
            case GAMESTATUS.GAME4ACTION:
                emitSocketEvent('send_update_initial_value_game_4', {
                    jobs: defaultJobs4,
                    currentAnswers: initializeDefaultAnswersGame4(),
                });
                break;
        }
    } else {
        handleUpdateOverlayDescriptions(
            overlayContent[gameStatus].tv,
            OverlayType.NEUTRAL
        );
    }
};

export const handleUpdateOverlayIndex = (overlayIndex: number) => {
    emitSocketEvent('send_update_overlay_index', { overlayIndex });
};

export const handleUpdateOverlayType = (overlayType: OverlayType) => {
    emitSocketEvent('send_update_overlay_type', { overlayType });
};

export const handleUpdateOverlayDescriptions = (
    overlayDescriptions: OverlayDescription[],
    overlayType: OverlayType
) => {
    emitSocketEvent('send_update_overlay_descriptions', {
        overlayDescriptions,
        overlayType,
    });
};

export const handleNextOrPreviousOverlayDescription = (
    action: 'next' | 'previous'
) => {
    const nextGameStatus = getNextGameStatus();
    const { overlayDescriptions, currentOverlayIndex } = useOverlayStore.getState();

    if (action === 'next') {
        const isLastOverlayMessage =
            overlayDescriptions &&
            currentOverlayIndex === overlayDescriptions.length - 1;

        if (isLastOverlayMessage) {
            handleGoToGameStatus(nextGameStatus);
        } else {
            handleUpdateOverlayIndex(currentOverlayIndex + 1);
        }
    } else if (action === 'previous') {
        const isFirstMessage = overlayDescriptions && currentOverlayIndex === 0;
        if (!isFirstMessage) handleUpdateOverlayIndex(currentOverlayIndex - 1);
    }
};

export const playSound = (audio: string): object => {
    const sound = new Audio(audio);
    return sound.play();
};
