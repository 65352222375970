import {
    handleGoToGameStatus,
    handleTabletStatus,
} from '@/services/global/globalHandleFunctions';
import { emitSocketEvent, getNextGameStatus } from '@/services/global/globalUtils';
import { useGame4Store } from '@/store/games/game4Store';
import {
    type DemandStatus,
    type JobOmitGame4,
    type PossibleJobGame4Ids,
} from '@/types/games/game4/types';

export const handleSelectGame4Job = (currentJob: JobOmitGame4 | null) => {
    handleTabletStatus({
        blue: false,
        red: false,
        green: true,
        orange: true,
    });
    emitSocketEvent('send_select_job_4', {
        currentJob,
    });
};

export const handleSelectCurrentAnswer = (
    selectedJobId: PossibleJobGame4Ids,
    index: number,
    nextStatus: DemandStatus
) => {
    emitSocketEvent('send_select_current_answer', {
        selectedJobId,
        index,
        nextStatus,
    });
};

export const handleResetCurrentAnswers = () => {
    emitSocketEvent('send_reset_current_answers', {});
};

export const handleValidateJobGame4 = () => {
    const selectedJob = useGame4Store.getState().selectedJob;
    if (!selectedJob) return;

    handleTabletStatus({
        red: false,
        blue: true,
        green: false,
        orange: false,
    });

    emitSocketEvent('send_validate_job_game_4', {
        job: selectedJob,
        isValidated: true,
    });
};

export const handleValidateGame4 = () => {
    const jobsGame4 = useGame4Store.getState().jobsGame4;
    const areAllJobsValidated = jobsGame4.every((job) => job.isValidated);

    if (areAllJobsValidated) {
        handleGoToGameStatus(getNextGameStatus());
    }

    handleTabletStatus({
        blue: false,
        red: false,
        green: true,
        orange: false,
    });

    handleSelectGame4Job(null);
};
