import { useEffect } from 'react';
import { handleSendIsAudioPlaying } from '@/services/global/globalHandleFunctions';

interface AudioProps {
    src: string;
    trackSource?: string;
    onEndedCallback?: () => void;
    isLooping?: boolean;
}

const AudioComponent: React.FC<AudioProps> = ({
    src,
    trackSource,
    isLooping = false,
    onEndedCallback,
}) => {
    const onPlay = () => {
        handleSendIsAudioPlaying(true);
    };

    const onEnd = () => {
        handleSendIsAudioPlaying(false);
        onEndedCallback?.();
    };

    useEffect(() => {
        return () => {
            handleSendIsAudioPlaying(false);
        };
    });

    return (
        <audio
            autoPlay
            src={src}
            onEnded={() => onEnd()}
            onPlaying={() => onPlay()}
            loop={isLooping}
        >
            <track kind='captions' srcLang='fr' src={trackSource} />
        </audio>
    );
};

export default AudioComponent;
