import AudioComponent from '@/features/components/AudioComponent';
import TextAnimation from '@/features/components/text/TextAnimation';
import { MemoizedConfettis } from '@/features/devices/tv/components/confettis/Confettis';
import styles from '@/features/devices/tv/components/prevention/prevention.module.scss';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import type { OverlayContent } from '@/types/global/types';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';

const Prevention: React.FC = () => {
    const {
        currentTitle,
        currentText,
        currentContent,
        currentFooter,
        currentAudioFooter,
        currentTextAudio,
    } = useCurrentOverlay();

    const [cardsToDisplay, setCardsToDisplay] = useState<OverlayContent[]>([]);
    const [showFooter, setShowFooter] = useState(false);

    const onTitleAudioEnd = useCallback(() => {
        if (!currentContent) return;
        setCardsToDisplay([currentContent[0]]);
    }, [currentContent]);

    const onAudioCardEnd = useCallback(() => {
        if (!currentContent) return;

        const actualCards = cardsToDisplay.length;
        if (actualCards === currentContent.length) {
            setShowFooter(true);
        } else {
            setCardsToDisplay((state) =>
                state
                    ? Array.from(new Set([...state, currentContent[actualCards]]))
                    : [currentContent[0]]
            );
        }
    }, [currentContent, cardsToDisplay.length]);

    useEffect(() => {
        if (!currentContent) return;

        setShowFooter(false);
        setCardsToDisplay([]);
    }, [currentContent]);

    return (
        <div className={styles.innerContainer}>
            <div className={styles.textContainer}>
                <h1>{currentTitle}</h1>
                <TextAnimation text={currentText ?? ''} />
                <AudioComponent
                    src={currentTextAudio}
                    onEndedCallback={onTitleAudioEnd}
                />
            </div>
            <MemoizedConfettis top={0} right={0} />
            <MemoizedConfettis bottom={0} left={0} />

            <div className={styles.cardContainer}>
                <AnimatePresence>
                    {cardsToDisplay.map(
                        (item) =>
                            item && (
                                <motion.div
                                    key={item.text}
                                    className={styles.card}
                                    style={{
                                        width: `min(20%, ${100 / (cardsToDisplay.length + 2)}%)`,
                                    }}
                                    initial={{ x: '100%' }}
                                    animate={{ x: 0 }}
                                    exit={{ x: '-1000%' }}
                                    transition={{
                                        type: 'spring',
                                        stiffness: 100,
                                    }}
                                >
                                    <AudioComponent
                                        src={item.audio}
                                        onEndedCallback={onAudioCardEnd}
                                    />
                                    <img src={item.icon} alt='icone' />
                                    <p>{item.text}</p>
                                    <ul>
                                        {item.list.map((listItem, index) => (
                                            <li key={index}>{listItem}</li>
                                        ))}
                                    </ul>
                                </motion.div>
                            )
                    )}
                </AnimatePresence>
            </div>
            <div className={styles.footer}>
                {showFooter && currentFooter ? (
                    <>
                        <TextAnimation text={currentFooter} />
                        <AudioComponent src={currentAudioFooter!} />
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default Prevention;
