import styles from '@/features/devices/tablets/components/contraintSelection/buttonFactor.module.scss';
import { handleColor } from '@/services/games/game4/gameFourUtils';
import { playSound } from '@/services/global/globalHandleFunctions';
import { type DemandStatus } from '@/types/games/game4/types';
import { motion } from 'framer-motion';

interface ButtonFactorProps {
    onClick?: () => void;
    text: DemandStatus;
    soundPath?: string;
}

const ButtonFactor: React.FC<ButtonFactorProps> = ({
    text,
    onClick,
    soundPath,
}) => {
    return (
        <motion.button
            onClick={() => onClick?.()}
            className={styles[`${handleColor(text)}Container`]}
            onMouseDown={() => soundPath && playSound(soundPath)}
            whileTap={{
                scale: 0.9,
            }}
        >
            <p>{text}</p>
        </motion.button>
    );
};

export default ButtonFactor;
