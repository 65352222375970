import Button from '@/features/devices/tablets/components/buttons/Button';
import ClickableItem from '@/features/devices/tablets/components/list/components/ClickableItem';
import styles from '@/features/devices/tablets/components/list/risksOrLeversList.module.scss';
import globalData from '@/data/global.json';
import { useGame3Store } from '@/store/games/game3Store';
import {
    handleSelectArgumentGame3,
    handleValidateArgumentGame3,
} from '@/services/games/game3/gameThreeHandleFunctions';
import { getArguments } from '@/services/games/game3/gameThreeUtils';
import { useGameStore } from '@/store/gameStore';

const ArgumentList: React.FC = () => {
    const game2Arguments = getArguments();
    const selectedArgument = useGame3Store.use.selectedArgument();
    const argumentIdsAlreadyUsed = useGame3Store.use.argumentIdsAlreadyUsed();
    const isAudioPlaying = useGameStore.use.isAudioPlaying();

    return (
        <>
            <div className={styles.listContainer}>
                <>
                    {game2Arguments.map((argument) => {
                        const isSelected = selectedArgument?.id === argument.id;
                        const isAlreadyUsed = argumentIdsAlreadyUsed.includes(
                            argument.id
                        );

                        return (
                            <ClickableItem
                                key={argument.id}
                                isSelected={isSelected}
                                description={argument.name}
                                onClick={() => handleSelectArgumentGame3(argument)}
                                disabled={isAlreadyUsed || isAudioPlaying}
                            />
                        );
                    })}
                </>
            </div>
            {selectedArgument && (
                <Button
                    text={globalData.global.validate}
                    color='green'
                    onClick={handleValidateArgumentGame3}
                    additionalStyle={{
                        position: 'absolute',
                        bottom: '5%',
                    }}
                />
            )}
        </>
    );
};

export default ArgumentList;
