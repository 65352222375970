import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';

export default function useCurrentOverlay() {
    const mode = useGameStore.use.mode() as 'pochette' | 'cadenas';
    const gameStatus = useGameStore.use.gameStatus();

    const {
        overlayDescriptions: currentOverlayDescriptions,
        currentOverlayIndex,
        overlayType,
    } = useOverlayStore();

    const overlayDescription = currentOverlayDescriptions[currentOverlayIndex];

    const currentTextAudio = overlayDescription?.audio;
    const currentTitle = overlayDescription?.title;
    const currentText = gameStatus?.includes('UNLOCK')
        ? overlayDescription?.[`${mode}Text`]
        : overlayDescription?.text;
    const currentFooter = overlayDescription?.footer;
    const currentContent = overlayDescription?.content;
    const currentAudioFooter = overlayDescription?.footerAudio;

    return {
        currentTextAudio,
        currentTitle,
        currentText,
        overlayType,
        currentContent,
        currentFooter,
        currentAudioFooter,
    };
}
