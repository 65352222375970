/**
 * Custom type to get all values of an object, as same as enum values.
 */
export type ObjectValues<T> = T[keyof T];

/**
 * Custom type to enable completion of the snippet. Works as same as the original {@link Omit} type.
 */
export type StrictOmit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type Nullable<T> = T | null;

export type Maybe<T> = T | undefined;

export const GAMESTATUS = {
    WELCOME_SCREEN: 'WELCOME_SCREEN',
    INTRODUCTION: 'INTRODUCTION',

    GAME1INTRODUCTION: 'GAME1INTRODUCTION',
    GAME1ACTION: 'GAME1ACTION',
    GAME1PREVENTION: 'GAME1PREVENTION',
    GAME1UNLOCK: 'GAME1UNLOCK',

    GAME2INTRODUCTION: 'GAME2INTRODUCTION',
    GAME2ACTION: 'GAME2ACTION',

    GAME3INTRODUCTION: 'GAME3INTRODUCTION',
    GAME3ACTION: 'GAME3ACTION',
    GAME3PREVENTION: 'GAME3PREVENTION',
    GAME3UNLOCK: 'GAME3UNLOCK',

    GAME4INTRODUCTION: 'GAME4INTRODUCTION',
    GAME4ACTION: 'GAME4ACTION',
    GAME4PREVENTION: 'GAME4PREVENTION',
    GAME4UNLOCK: 'GAME4UNLOCK',

    FINISHED: 'FINISHED',
} as const;

export type GameStatus = ObjectValues<typeof GAMESTATUS>;

export type DeviceColor = 'blue' | 'green' | 'orange' | 'red';
export type DeviceColorWithTV = DeviceColor | 'tv';
export type DeviceColorWithTvAndMediator = DeviceColorWithTV | 'mediator';

export enum OverlayType {
    NEUTRAL = 'neutral',
    SUCCESS = 'success',
    ERROR = 'error',
    UNLOCK = 'unlock',
}

export interface OverlayContent {
    id: number;
    text: string;
    audio: string;
    list: string[];
    icon?: string;
}

export interface OverlayDescription {
    id: number;
    title: string;
    text?: string;
    cadenasText?: string;
    pochetteText?: string;
    character?: string;
    audio: string;
    content?: OverlayContent[];
    footer?: string;
    footerAudio?: string;
}

export interface Message {
    id: number;
    title: string;
    text: string;
    audio: string;
}

export interface ResponseText {
    id: number;
    responseText: string;
    audioPath: string;
}

export interface Job {
    id: number;
    slug: string;
    name: string;
    firstName: string;
    icon: string;
    fullIcon: string;
}
